import React, {useState} from "react";
import Table from "react-bootstrap/Table";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import {useGetChatsQuery} from "../../../store/reducers/adminApi";
import {useNavigate} from "react-router";
import {InputGroup} from "react-bootstrap";
import {debounce} from "chart.js/helpers";
import {Link} from "react-router-dom";

export function Chats() {
    const [page, setPage] = useState(1)
    const [searchEmail, setSearchEmail] = useState("");

    // Assuming your API endpoint accepts email as a query parameter
    const {isLoading, data: chats, refetch} = useGetChatsQuery({
        page,
        email: searchEmail
    });


    // Debounce the search to avoid too many API calls
    const debouncedSearch = debounce((value) => {
        setSearchEmail(value);
        setPage(1); // Reset to first page when searching
    }, 500);
    const handleSearchChange = (e) => {
        debouncedSearch(e.target.value);
    };

    // const {isLoading, isFetching, isSuccess, isError, error, data: chats, refetch} = useGetChatsQuery(page);

    const navigate = useNavigate();

    return (
        <div>
            Page # {page}
            <div className="flex items-center space-x-2">
                <InputGroup className="max-w-md">
                    <input
                        type="text"
                        placeholder="Search by user email..."
                        onChange={handleSearchChange}
                        className="pl-2"
                    />
                </InputGroup>
            </div>
            <Table striped bordered hover className="table-hover">
                <thead>
                <tr>
                    <th>Psychic</th>
                    <th>User</th>
                    <th>Created At</th>
                    <th>Status</th>
                </tr>
                </thead>
                <tbody>
                {chats &&
                    chats.map((chat) => {
                        const active = chat.chatSessions.find((chatSession) => chatSession.status === "OPEN");
                        const psychic = chat.users.find((user) => user.user_role === "psychic" || user.user_role !== "user");
                        const user = chat.users.find((user) => user.user_role === "user" || user.user_role !== "psychic");

                        return (
                            // <tr key={chat.chatID} onClick={() => navigate(`/chat/${chat.chatID}`)}>
                            <tr key={chat.chatID}>
                                <td><Link to={`/chat/${chat.chatID}`}>{psychic.name}</Link></td>
                                <td><Link to={`/chat/${chat.chatID}`}>{user.name}</Link></td>
                                <td>{moment(chat.created_at).format("MM/DD/YYYY")}</td>
                                <td>
                                    <Badge bg={active ? "success" : "danger"}>{active ? "active" : "closed"}</Badge>
                                </td>
                            </tr>
                        );
                    })}
                Current Page: {page}
                {page > 1 ? <button style={{marginRight: '3px', marginLeft: '3px'}} onClick={() => setPage(page - 1)}>
                    Previous
                </button> : <></>}

                {chats != null && chats.length > 0 ?
                    <button style={{marginRight: '3px', marginLeft: '3px'}} onClick={() => setPage(page + 1)}>
                        Next
                    </button> : <></>}
                </tbody>
            </Table>
        </div>
    );
}
