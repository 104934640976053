import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {getAuth} from "firebase/auth";
import {permissions} from "../../config/permissions";
import {transformServerMessage} from "./utils";
import {Chat} from "../../pages/dashboard/chats/chat";

export const adminApi = createApi({
    reducerPath: "adminApi",
    baseQuery: fetchBaseQuery({
        baseUrl: "/api/admin",
        prepareHeaders: async (headers, {getState}) => {
            const auth = getAuth();

            const token = auth.currentUser
                ? await auth.currentUser.getIdToken(true)
                : null;

            if (token) {
                headers.set("authorization", `Bearer ${token}`);
            }

            return headers;
        },
        fetchFn: async (input) => {
            const controller = new AbortController();
            const id = setTimeout(() => {
                controller.abort();
            }, 5000);
            const res = await fetch(input);
            clearTimeout(id);
            return Promise.resolve(res);
        },
    }),
    tagTypes: [
        "User",
        "Chat",
        "Transfer",
        "Review",
        "Report",
        "ChatValueCode",
        "Category",
        "BlockUser",
        "ChatRequestAnalytics",
    ],
    endpoints: (builder) => ({
        getUser: builder.query<User, string>({
            query: (userID) => ({url: `user/${userID}`}),
            providesTags: ["User"],
        }),

        getUsers: builder.query<User[], {
            page: number | void,
            email: string | void,
            name: string | void,
            searchQuery: string | void
        }>({
            query: (args) => (
                {
                    url: `user?page=${args.page ?? 1}` +
                        `${args.email != null ? `&email=${args.email}` : ""}` +
                        `${args.name != null ? `&name=${args.name}` : ""}` +
                        `${args.searchQuery != null ? `&searchQuery=${args.searchQuery}` : ""}`
                }
            ),
            providesTags: ["User"],
        }),
        getPsychics: builder.query<User[], number | void>({
            query: (page: number) => ({url: `psychic?page=${page ?? 1}`}),
            providesTags: ["User"],
        }),
        getApplications: builder.query<User[], number | void>({
            query: (page: number) => ({url: `applications`}),
            providesTags: ["User"],
        }),
        updateUser: builder.mutation<User, Partial<User> & Pick<User, "userID">>({
            query: ({userID, ...patch}) => ({
                url: `user/${userID}`,
                method: "PUT",
                body: patch,
            }),
            invalidatesTags: ["User"],
        }),
        getCategories: builder.query<any[], null>({
            query: () => ({url: "categories"}),
            providesTags: ["Category"],
        }),
        getChats: builder.query<Chat[], { page: number, email: string}>({
            query: ({ page, email}) => ({url: `chat?page=${page ?? 1}&email=${email ?? ""}`}),
            providesTags: ["Chat"],
        }),
        getChatRequestAnalytics: builder.query<{ analytics: ChatRequestAnalytics[], userStats: any[] }, number | void>({
            // getChatRequestAnalytics: builder.query<ChatRequestAnalytics[], number | void>({
            query: () => ({url: `chat-request-analytics`}),
            providesTags: ["ChatRequestAnalytics"],
        }),
        getChat: builder.query<Chat, string>({
            query: (chatID) => ({url: `chat/${chatID}`}),
            providesTags: (result, error, arg) => [{type: "Chat", id: arg}],
            transformResponse(chat: Chat) {
                chat.messages = transformServerMessage(
                    chat.messages,
                    chat.users
                ) as ChatMessage[];
                chat.messages = chat.messages.reverse();
                return chat;
            },
        }),
        getTransfers: builder.query<Transfer[], null>({
            query: () => ({url: "transfer"}),
            providesTags: ["Transfer"],
        }),
        updateTransfers: builder.mutation<Transfer, Transfer[]>({
            query: (transfers) => ({
                url: `transfer`,
                method: "PUT",
                body: transfers,
            }),
            invalidatesTags: ["Transfer"],
        }),
        getReviews: builder.query<Review[], null>({
            query: () => ({url: "review"}),
            providesTags: ["Review"],
        }),
        updateReview: builder.mutation<
            Review,
            Partial<Review> & Pick<Review, "reviewID">
        >({
            query: ({reviewID, ...patch}) => ({
                url: `review/${reviewID}`,
                method: "PUT",
                body: patch,
            }),
            invalidatesTags: ["Review"],
        }),
        removeReview: builder.mutation<
            Review,
            Partial<Review> & Pick<Review, "reviewID">
        >({
            query: ({reviewID, ...patch}) => ({
                url: `review/${reviewID}`,
                method: "DELETE",
                body: patch,
            }),
            invalidatesTags: ["Review"],
        }),
        getReports: builder.query<UserReport[], null>({
            query: () => ({url: "report"}),
            providesTags: ["Report"],
        }),
        updateReport: builder.mutation<
            UserReport,
            Partial<UserReport> & Pick<UserReport, "id">
        >({
            query: ({id, ...patch}) => ({
                url: `report/${id}`,
                method: "PUT",
                body: patch,
            }),
            invalidatesTags: ["Report"],
        }),
        getChatValueCodes: builder.query<ChatValueCode[], null>({
            query: () => ({url: "chat-value-code"}),
            providesTags: ["ChatValueCode"],
        }),
        getBlockedUsers: builder.query<BlockUser[], null>({
            query: () => ({
                url: "user/blocked",
            }),
        }),
        postBlockUser: builder.mutation<{ email: string }, { email: string }>({
            query: (data) => ({
                url: "user/block-email",
                method: "POST",
                body: data,
            }),
        }),
        patchBlockUser: builder.mutation<null, { id: string }>({
            query: ({id}) => ({
                url: "user/block-email/" + id,
                method: "PATCH",
            }),
        }),
    }),
});

export const {
    useGetUsersQuery,
    useUpdateUserMutation,
    useGetChatQuery,
    useGetChatsQuery,
    useGetUserQuery,
    useGetCategoriesQuery,
    useGetTransfersQuery,
    useGetReviewsQuery,
    useUpdateReviewMutation,
    useGetReportsQuery,
    useUpdateReportMutation,
    useGetChatValueCodesQuery,
    useUpdateTransfersMutation,
    usePostBlockUserMutation,
    useGetBlockedUsersQuery,
    usePatchBlockUserMutation,
    useGetChatRequestAnalyticsQuery,
    useGetPsychicsQuery,
    useGetApplicationsQuery,
    useRemoveReviewMutation
} = adminApi;
